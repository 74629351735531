type Breakpoint = 'base' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

type Breakpoints = Record<Breakpoint, number>;

const defaultBreakpoints: Breakpoints = {
  base: 0,
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1280,
  '2xl': 1536,
};

export type ResponsiveQuery = Partial<Record<Breakpoint, any>>;

/**
 * Adapts a responsive query based on the current width and breakpoints.
 * @param {Object} options - The options for the responsive query adapter.
 * @param {ResponsiveQuery} options.responsiveQuery - The responsive query object.
 * @param {number} options.width - The current width.
 * @param {Breakpoints} [options.breakpoints=defaultBreakpoints] - The breakpoints object.
 * @returns {*} The adapted responsive query result.
 */
export function responsiveQueryAdapter({
  responsiveQuery,
  width,
  breakpoints = defaultBreakpoints,
}: {
  responsiveQuery: ResponsiveQuery;
  width: number;
  breakpoints?: Breakpoints;
}) {
  let result = responsiveQuery.base;

  for (const breakpoint of ['sm', 'md', 'lg', 'xl', '2xl']) {
    if (width >= breakpoints[breakpoint] && responsiveQuery[breakpoint]) {
      result = responsiveQuery[breakpoint];
    }
  }

  return result ?? '';
}
