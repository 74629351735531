import { Box } from '@chakra-ui/react';
import { MagnoliaImage } from '~/types/Magnolia';
import { Divider, Text } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Color, getColor } from '@utils/getColor';
import DAMImage from '@components/Image/DAMImage';
import Container from '@components/LayoutStructure/Container';
import { useRef, useEffect, useState } from 'react';
import {
  ResponsiveQuery,
  responsiveQueryAdapter,
} from '@utils/responsiveQueryAdapter';

interface Testimonial {
  author: string;
  authorTitle?: string;
  authorTextColor?: Color;
  body: string;
  image?: MagnoliaImage;
  textColor?: Color;
}

interface Props {
  bg: Color;
  smallTestimonial: Testimonial;
  largeTestimonial: Testimonial;
  removeTopMargin?: boolean;
  removeBottomMargin?: boolean;
}

const DualTestimonial = ({
  bg,
  smallTestimonial,
  largeTestimonial,
  removeTopMargin,
  removeBottomMargin,
}: Props) => {
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const dividerOrientation = useBreakpointValue(
    {
      base: 'horizontal',
      lg: 'vertical',
    },
    {
      fallback: 'vertical',
    }
  ) as 'horizontal' | 'vertical';

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setContainerWidth(entry.contentRect.width);
        }
      });
      resizeObserver.observe(container);
      return () => {
        resizeObserver.unobserve(container);
      };
    }
  }, []);

  const responsiveStyles = (styles: ResponsiveQuery) =>
    responsiveQueryAdapter({
      responsiveQuery: styles,
      width: containerWidth,
    });

  const largeTestimonialImageShiftUp = '36px';

  return (
    <Container
      mt={responsiveStyles(
        // We still need the margin because the Large Testimonial image is being shifted up
        removeTopMargin
          ? { base: largeTestimonialImageShiftUp, lg: 0 }
          : { base: '86px', lg: 12 }
      )}
      mb={removeBottomMargin ? '0 !important' : undefined}
      ref={containerRef}
    >
      <Box
        px={responsiveStyles({ base: 3.5, md: 6, lg: 4 })}
        as="section"
        display="flex"
        flexDirection={responsiveStyles({ base: 'column', lg: 'row' })}
        gap={responsiveStyles({ base: 5, lg: 8 })}
        bg={getColor(bg.color || 'white', bg.range || '500')}
        boxShadow="md"
        borderRadius="xl"
        alignSelf="center"
        lineHeight="27px"
        ml={responsiveStyles({ base: 6, lg: '90px' })}
        mr={6}
      >
        {largeTestimonial.image && (
          <Box
            flex={responsiveStyles({ lg: 2 })}
            position="relative"
            boxShadow="md"
            borderRadius="xl"
            height={responsiveStyles({ base: '20rem', lg: 80 })}
            overflow="hidden"
            maxWidth="300px"
            width="100%"
            alignItems="center"
            mx="auto"
            ml={responsiveStyles({ lg: '-82px' })}
            my={responsiveStyles({ base: 'auto', lg: 10 })}
            mt={responsiveStyles({
              base: `-${largeTestimonialImageShiftUp}`,
              lg: 10,
            })}
          >
            <DAMImage
              src={largeTestimonial.image}
              fill
              style={{ objectFit: 'cover' }}
            />
          </Box>
        )}
        <Box flex={3} my="auto">
          <Text
            as="h3"
            fontSize={responsiveStyles({ base: '4xl', lg: '3xl' })}
            fontWeight="bold"
            aria-describedby="large-testimonial-author-title"
            color={getColor(
              largeTestimonial.authorTextColor?.color || 'primary',
              largeTestimonial.authorTextColor?.range || '700'
            )}
            mt={responsiveStyles({ base: 6, lg: 0 })}
          >
            {largeTestimonial.author}
          </Text>
          <Text
            as="h4"
            fontSize="lg"
            fontWeight="bold"
            mb={6}
            mt={2}
            id="large-testimonial-author-title"
            color={getColor(
              largeTestimonial.authorTextColor?.color || 'primary',
              largeTestimonial.authorTextColor?.range || '700'
            )}
          >
            {largeTestimonial.authorTitle}
          </Text>
          <Box
            color={getColor(
              largeTestimonial.textColor?.color || 'gray',
              largeTestimonial.textColor?.range || '700'
            )}
            fontSize={responsiveStyles({ base: 'lg', lg: 'md' })}
            dangerouslySetInnerHTML={{ __html: largeTestimonial.body }}
          />
        </Box>

        <Box
          display="flex"
          flex={3}
          alignSelf="end"
          gap={responsiveStyles({ base: 5, lg: 10 })}
          flexDirection={responsiveStyles({ base: 'column', lg: 'row' })}
          my="auto"
        >
          <Divider
            orientation={dividerOrientation}
            bg="gray.400"
            height={responsiveStyles({ base: '1px', lg: 'auto' })}
          />
          <Box
            flex={3}
            as="blockquote"
            color={getColor(
              smallTestimonial.textColor?.color || 'primary',
              smallTestimonial.textColor?.range || '500'
            )}
          >
            <Box
              fontSize={responsiveStyles({ base: 'lg', lg: 'md' })}
              dangerouslySetInnerHTML={{ __html: smallTestimonial.body }}
              mb={6}
              position="relative"
              color={getColor(
                largeTestimonial.textColor?.color || 'gray',
                largeTestimonial.textColor?.range || '700'
              )}
              mr={{ lg: 11 }}
            />
            <footer>
              <Box
                as="cite"
                display="flex"
                gap={responsiveStyles({ base: 2, lg: 4 })}
                alignItems="center"
                margin="auto"
                mb={responsiveStyles({ base: 6, lg: 0 })}
              >
                {smallTestimonial.image && (
                  <DAMImage
                    src={smallTestimonial.image}
                    style={{
                      borderRadius: '50%',
                      width: '32px',
                      height: '32px',
                      objectFit: 'cover',
                    }}
                  />
                )}
                <Box
                  fontStyle="normal"
                  color={getColor(
                    smallTestimonial.authorTextColor?.color || 'primary',
                    smallTestimonial.authorTextColor?.range || '900'
                  )}
                >
                  <Text as="h3" fontWeight="bold">
                    {smallTestimonial.author}
                  </Text>
                  <Text as="h4" lineHeight={5}>
                    {smallTestimonial.authorTitle}
                  </Text>
                </Box>
              </Box>
            </footer>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default DualTestimonial;
